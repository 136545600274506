<template>
  <div class="SalesDashboard">
    <PaceWidget/>
  </div>
</template>

<style lang="postcss" scoped>
.SalesDashboard {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import PaceWidget from "@/components/widgets/PaceWidget";
export default {
  name: "SalesDashboard",
  components: {PaceWidget},
  props: {
    // https://vuejs.org/v2/guide/components.html#Prop-Validation
    // propname: String
  },
  computed: {
    // ...mapGetters('',[''])
    // ...mapState('',[''])
  },
  watch: {},
  mixins: [],
  data: function () {
    return {};
  },
  methods: {
    // ...mapActions('',[''])
    // ...mapMutations('',[''])
  },
  created() {
  },
  mounted() {
  },
};
</script>
