<template>
  <div class="GenericTable">
    <div v-if="haveFilters">
      <v-icon>mdi-filter</v-icon>
      <v-btn v-for="f in filters" :key="f" @click="filterClick(f)">{{ f }}</v-btn>
    </div>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    ></v-text-field>
    <v-data-table
        dense
        :headers="headers"
        :items="this.roundedDataset"
        :item-key="sku"
        class="elevation-1"
        @click:row="rowClick"
        :search="search"
        :footer-props="footer_props"
        :group-by="groupBy"
    >
      <!-- pass through scoped slots -->
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData"/>
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"/>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="postcss" scoped>
.GenericTable {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { mapState, mapMutations, mapGetters } from "vuex";
import { filterMixins } from "../../mixins/filterMixins";
import { apiMixins } from "../../mixins/apiMixins";
// import ResponsiveWrapper from "../layout/ResponsiveWrapper";

export default {
  name: "GenericTable",
  components: {},
  props: {
    // https://vuejs.org/v2/guide/components.html#Prop-Validation
    // propname: String
    datasetUrl: {
      type: String,
      default: "",
      required: false,
    },
    groupBy: {
      type: String,
      required: false,
      default: null
    },
    dataset: {
      type: Array,
      required: true
    },
    clickUrl: {
      type: String,
      default: "#",
      required: false,
    },
    clickRoute: {
      type: String,
      default: "",
      required: false,
    },
    clickKey: {
      type: String,
      default: "",
      required: false,
    },
    headers: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: false
    },
    roundedKeys: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  computed: {
    ...mapGetters("filters", ["filteredQueryString"]),
    ...mapState("filters", ["from", "to", "sku"]),
    url: function () {
      return this.filterDataURL(this.dataUrl, this.from, this.to, this.sku);
    },
    haveFilters: function () {
      if ((Array.isArray(this.filters)) && this.filters.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    roundedDataset: function () {
      return this.dataset.map(x => {
        let currentSet = x;
        Object.keys(x).forEach(key => {
          if ((typeof x[key] == "number") && (key in this.roundedKeys)) {
            currentSet[key] = Math.round(x[key]);
          }
        });
        return currentSet;
      });
    },
  },
  watch: {},
  mixins: [filterMixins, apiMixins],
  data: function () {
    return {
      // dataset: [],
      // headers: [
      //   {text: "Category", value: "category", align: "start", sortable: true},
      //   {text: "Customer", value: "customer", sortable: true},
      //   {text: "Code", value: "code", sortable: true},
      //   {text: "Invoice", value: "invoice", sortable: true},
      //   {text: "Total", value: "total", align: "end", sortable: true},
      //   {text: "Date", value: "date", align: "end", sortable: true},
      // ],
      footer_props: {
        // showFirstLastPage: true,
        // firstIcon: "mdi-arrow-collapse-left",
        // lastIcon: "mdi-arrow-collapse-right",
        // prevIcon: "mdi-minus",
        // nextIcon: "mdi-plus",
        "itemsPerPageOptions": [15, 30, 50, 100, -1]
      },
      search: "",
    };
  },
  methods: {
    // ...mapActions('',[''])
    ...mapMutations("filters", ["SET_QUERY_PARAMETER", "UNSET_QUERY_PARAMETER"]),

    rowClick: function (value) {
      if (this.clickKey === "sku") {
        this.UNSET_QUERY_PARAMETER({key: "customer"});
        this.UNSET_QUERY_PARAMETER({key: "channel"});
      } else if (this.clickKey === "customer") {
        this.UNSET_QUERY_PARAMETER({key: "sku"});
        this.UNSET_QUERY_PARAMETER({key: "channel"});
      }
      this.SET_QUERY_PARAMETER({key: this.clickKey, value: value[this.clickKey]});
      this.$router.push({name: this.clickRoute});
    },
    filterClick: function (filter) {
      this.search = filter;
    }
  },
  created() {
  },
  mounted() {
  },
};
</script>
