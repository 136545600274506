import Vue from "vue";
import VueRouter from "vue-router";
import HomeDashboard from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeDashboard
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "dho" */ "../views/DhoLogin.vue")
  },
  {
    path: "/status",
    name: "status",
    component: () => import(/* webpackChunkName: "dho" */ "../views/StatusView")
  },
  {
    path: "/usage",
    name: "usage",
    component: () => import(/* webpackChunkName: "dho" */ "../views/UsageView")
  },
  {
    path: "/debug",
    name: "debug",
    component: () => import(/* webpackChunkName: "dho" */ "../views/DebugView")
  },
  {
    path: "/reports",
    component: () => import(/* webpackChunkName: "dho_reports" */ "../views/ReportsView.vue"),
    children: [
      {
        path: "",
        name: "report_list",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../views/ReportsView.vue"),
      },
      {
        path: "stock",
        name: "report_stock",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/StockReport")
      },
      {
        path: "accounts_payable",
        name: "report_accounts_payable",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/AccountsPayableReport")
      },
      {
        path: "monthly_change",
        name: "report_monthly_change",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/MonthlyChangeReport")
      },
      {
        path: "sku_type_summary",
        name: "report_sku_type_summary",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/SkuTypeSummaryReport")
      },
      {
        path: "sku_summary",
        name: "report_sku_summary",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/SkuSummaryReport")
      },
      {
        path: "sku_detail",
        name: "report_sku_detail",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/SkuDetailReport")
      },
      {
        path: "customer_summary",
        name: "report_customer_summary",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/CustomerSummaryReport")
      },
      {
        path: "customer_detail",
        name: "report_customer_detail",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/CustomerDetailReport")
      },
      {
        path: "channel_summary",
        name: "report_channel_summary",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/ChannelSummaryReport")
      },
      {
        path: "channel_detail",
        name: "report_channel_detail",
        component: () => import(/* webpackChunkName: "dho_reports" */ "../components/reports/ChannelDetailReport")
      }
    ]
  },
  {
    path: "/test",
    name: "test",
    children: [
      {
        path: "/debug",
        name: "test-debug",
        component: () => import(/* webpackChunkName: "dho" */ "@/components/widgets/DebugWidget.vue")
      }
    ],
    component: () => import(/* webpackChunkName: "dho" */ "../views/DhoLogin.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
