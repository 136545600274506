<template>
  <div class="DhoToolbar" v-if="showAppBar">
    <v-app-bar app class="green white--text">
      <v-app-bar-nav-icon @click.stop="navIconClick">
        <v-icon class="white--text">
          mdi-hamburger
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="font-weight-bold">
        Visual Express <span class="red--text">MKF</span>
      </v-app-bar-title>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn icon @click="logoutClick">
          <v-icon class="white--text">
            mdi-logout
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<style lang="postcss" scoped>
.DhoToolbar {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { auth } from "@/plugins/fb";

export default {
  name: "DhoToolbar",
  components: {},
  props: {
    //propName: {
    //    type: String,
    //    required: false,
    //    default: '',
    //}
  },
  computed: {
    ...mapGetters("filters", ["filteredQueryString"]),
    ...mapState("filters", ["disable"]),
    showAppBar: function () {
      return !this.disable.appBar;
    },
  },
  watch: {},
  mixins: [],
  data: function () {
    return {
      showNavDrawer: false
    };
  },
  methods: {
    ...mapActions("menus", ["setMainMenu"]),
    ...mapMutations("menus", ["TOGGLE_LEFT_NAV"]),
    // ...mapMutations('',[''])
    navIconClick: function () {
      // this.setMainMenu;
      // window.location.assign(`/?${this.filteredQueryString}`);
      this.TOGGLE_LEFT_NAV();
      // todo set this up
    },
    logoutClick: function () {
      auth.signOut();
      if (this.$route.name !== 'login') {
        this.$router.push({name: "login"});
      }
    },
    homeClick: function () {
      window.location.assign(`/?${this.filteredQueryString}`);
    }
  },
  created() {
  },
  mounted() {
  },
};
</script>
