import Vue from "vue";
import Vuex from "vuex";
import { filters } from "./filters";
import { menus } from "./menus";
import { user } from "./user";
import { meta } from "./meta";
import { persisted, persistedStatePlugin } from "@/store/persisted";
import { vuexfireMutations } from "vuexfire";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fbToken: "",
    fbEmail: ""
  },
  mutations: {
    ...vuexfireMutations,
    FB_LOG_IN_USER: (state, data) => {
      state.fbToken = data.token;
      state.fbEmail = data.email;
    },
    FB_LOG_OUT_USER: (state) => {
      state.fbToken = "";
    }
  },
  actions: {
    logInUser: async (context, data) => {
      context.commit("FB_LOG_IN_USER", data);
      return null;
    },
    logOutUser: async (context) => {
      context.commit("FB_LOG_OUT_USER");
      return null;
    }
  },
  modules: {
    filters,
    menus,
    user,
    meta,
    persisted
  },
  plugins: [persistedStatePlugin]
});
