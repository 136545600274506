import humanize from "humanize";

const formatMixin = {
  methods: {
    formatNumber: function (number, decimals = 0) {
      return humanize.numberFormat(number, decimals);
    },
  }
};

export { formatMixin };
