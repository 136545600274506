import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { FirebaseVue } from "./plugins/fb";
import store from "./store";
import router from "@/router";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import VueCookies from "vue-cookies";

import VueGoogleCharts from "vue-google-charts";

Vue.use(VueGoogleCharts);

Vue.config.productionTip = false;
Vue.component("App", App);
Vue.use(FirebaseVue);
Vue.use(VueCookies);

/**
 * Graphs
 */
/*
Vue.component("MonthlySalesPerSkuLine",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/MonthlySalesPerSkuLine"));
Vue.component("MonthlySkuProductSalesTable",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/MonthlySkuProductSalesTable"));
Vue.component("OnlineDailyQuantityLine",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/OnlineDailyQuantityLine"));
Vue.component("OnlineProductSalesTable",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/OnlineProductSalesTable"));
Vue.component("OnlineSalesQuantityPerPriceBar",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/OnlineSalesQuantityPerPriceBar"));

Vue.component("SalesPerChannelBar",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/SalesPerChannelBar"));
Vue.component("SalesPerChannelPie",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/SalesPerChannelPie"));
Vue.component("SalesChannelTable",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/SalesChannelTable"));
*/

/**
 * Dashboards
 */
/*
Vue.component("ApDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/ApDashboard"));
Vue.component("DebugDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/DebugDashboard"));
Vue.component("StockDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/StockDashboard"));
Vue.component("MonthChangeDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/MonthChangeDashboard"));
Vue.component("CustomerDetailDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/CustomerDetailDashboard"));
Vue.component("SkuDetailDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/SkuDetailDashboard"));
Vue.component("EachCustomerPerMonthDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/EachCustomerPerMonthDashboard"));
Vue.component("ChannelOverviewDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/ChannelOverviewDashboard"));
Vue.component("TopThirtyCustomersDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/TopThirtyCustomersDashboard"));
Vue.component("TopThirtySkuQuantityDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/TopThirtySkuQuantityDashboard"));
Vue.component("TopThirtySkuRevenueDashboard",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/del-dashboards/TopThirtySkuRevenueDashboard"));
*/

/**
 * Generic Layout
 */
/*
Vue.component("GenericTable",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/graphs/GenericTable"));
*/

/**
 * Layouts
 */
/*
Vue.component("ResponsiveWrapper",
  () => import(/!* webpackChunkName: "dho" *!/ "./components/layout/ResponsiveWrapper"));
*/

/**
 * Auth
 */
/*
Vue.component("DhoLogin",
  () => import(/!* webpackChunkName: "dho" *!/ "./views/DhoLogin"));
*/

new Vue({
  vuetify,
  render: h => h(App),
  store,
  router,
}).$mount("#app");
