import Vue from "vue";

import { firestoreAction } from "vuexfire";
import { db } from "@/plugins/fb";
import moment from "moment";

const meta = {
  namespaced: true,
  state: {
    fbCurrentRun: {},
    skus: {},
    usage: [],
    customers: {},
    server: {
      currentServer: ""
    }
  },
  getters: {
    currentServer: function (state) {
      return state.server.currentServer;
    },
    isUpdating: function (state) {
      if (!state.fbCurrentRun) {
        return true;
      }
      if (!state.fbCurrentRun.status) {
        return true;
      }
      if (state.fbCurrentRun.status !== "OK") {
        return true;
      }
      return false;
    },
    status: function (state) {
      return state.fbCurrentRun.status || "UNKNOWN";
    },
    started: function (state) {
      if (state.fbCurrentRun.started) {
        let date = new Date(1970, 0, 1);
        date.setSeconds(state.fbCurrentRun.started.seconds);
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
        // return date;
        // return TimeStamp.fromMillis(state.fbCurrentRun.started.seconds * 1000).toDate();
      } else {
        return 0;
      }
    },
    ended: function (state) {
      if (state.fbCurrentRun.ended) {
        let date = new Date(1970, 0, 1);
        date.setSeconds(state.fbCurrentRun.ended.seconds);
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
        // return date;
        // return TimeStamp.fromMillis(state.fbCurrentRun.started.seconds * 1000).toDate();
      } else {
        return 0;
      }
    },
    datasetTimestamp: function (state) {
      if (state.fbCurrentRun.dataset_timestamp) {
        return state.fbCurrentRun.dataset_timestamp;
      } else {
        return "Unknown";
      }
    },
    importSteps: function (state) {
      if ((state.fbCurrentRun.import) && (state.fbCurrentRun.import.length > 0)) {
        return state.fbCurrentRun.import.map(x => {
          return {
            item: x.human,
            status: x.ok,
            started: x.started || false,
            ended: x.ended || false
          };
        });
      } else {
        return [];
      }
    }
  },
  mutations: {
    SET_SERVER: function (state, data) {
      Vue.set(state.server, "currentServer", data);
    },
  },
  actions: {
    bindCurrentRun: firestoreAction(({bindFirestoreRef}) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("fbCurrentRun", db.collection("visex_meta").doc("currentRun"));
    }),
    bindSkus: firestoreAction(({bindFirestoreRef}) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("skus", db.collection("visex_meta").doc("skus"));
    }),
    bindUsage: firestoreAction(({bindFirestoreRef}) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("usage", db.collection("visex_meta").doc("usage_log").collection("usage_log"));
    }),
    setServer: function (context, data) {
      context.commit("SET_SERVER", data);
    },
  },
};

export { meta };
