import Vue from "vue";
import moment from "moment";

const filters = {
  namespaced: true,
  state: {
    showFilterDialog: false,
    queryParams: {},
    from: "",
    to: "",
    sku: "",
    limit: 0,
    dateFilterType: "date",
    updated: "",
    disable: {
      dateFrom: true,
      dateTo: true,
      sku: true,
      customer: true,
      limit: true,
      channel: true,
      all: false,
      appBar: false,
    }
  },
  getters: {
    filtersEnabled: function (state) {
      return !(state.disable.all);
    },
    showFilterDialog: function (state) {
      return state.showFilterDialog;
    },
    updated: function (state) {
      return state.updated;
    },
    filteredQueryString: function (state) {
      if (Object.keys(state.queryParams).length === 0) {
        return "";
      }

      let filteredKeys = Object.keys(state.queryParams)
        .filter(x => (state.queryParams[x] !== "" && state.queryParams[x] !== null && state.queryParams[x] !== "null"));

      let mappedKeys = filteredKeys.map(x => `${x}=${state.queryParams[x]}`);

      return mappedKeys.join("&");
    },
    hasDateFilters: function (state) {
      return ((state.queryParams.from) && (state.queryParams.to));
    },
    getSku: function (state) {
      if (state.queryParams.sku) {
        return state.queryParams.sku;
      } else {
        return null;
      }
    },
    getChannel: function (state) {
      if (state.queryParams.channel) {
        return state.queryParams.channel;
      } else {
        return null;
      }
    },
    getCustomer: function (state) {
      if (state.queryParams.customer) {
        return state.queryParams.customer;
      } else {
        return null;
      }
    },
    getDateFrom: function (state) {
      if (state.queryParams.from) {
        return state.queryParams.from;
      } else {
        return null;
      }
    },
    getDateTo: function (state) {
      if (state.queryParams.to) {
        return state.queryParams.to;
      } else {
        return null;
      }
    },
    daysBetween: function (state) {
      if ((state.queryParams.to) && (state.queryParams.from)) {
        let fromDate = moment(state.queryParams.from, "YYYY-MM-DD");
        let toDate = moment(state.queryParams.to, "YYYY-MM-DD");
        let diff = toDate.diff(fromDate, "days");
        return diff;
      }
      return 0;
    },
  },
  mutations: {
    HIDE_FILTER_DIALOG: function (state) {
      state.showFilterDialog = false;
    },
    SHOW_FILTER_DIALOG: function (state) {
      state.showFilterDialog = true;
    },
    SET_UPDATED: (state) => {
      Vue.set(state, "updated", Date.now());
    },
    SET_FROM: (state, data) => {
      Vue.set(state.queryParams, "from", data);
    },
    SET_TO: (state, data) => {
      Vue.set(state.queryParams, "to", data);
    },
    SET_SKU: (state, data) => {
      Vue.set(state.queryParams, "sku", data);
    },
    SET_CUSTOMER: (state, data) => {
      Vue.set(state.queryParams, "customer", data);
    },
    SET_LIMIT: (state, data) => {
      Vue.set(state.queryParams, "limit", data);
    },
    SET_QUERY_PARAMETER: (state, data) => {
      Vue.set(state.queryParams, data.key, data.value);
    },
    UNSET_QUERY_PARAMETER: (state, data) => {
      Vue.delete(state.queryParams, data.key);
    },
    UNSET_NON_DATE_QUERY: (state) => {
      Vue.delete(state.queryParams, "customer");
      Vue.delete(state.queryParams, "channel");
      Vue.delete(state.queryParams, "sku");
      Vue.delete(state.queryParams, "limit");
    },
    UNSET_ALL_QUERY: (state) => {
      Vue.delete(state.queryParams, "customer");
      Vue.delete(state.queryParams, "sku");
      Vue.delete(state.queryParams, "limit");
      Vue.delete(state.queryParams, "from");
      Vue.delete(state.queryParams, "to");
    },
    SET_DATE_FILTER_TYPE_MONTH: (state) => {
      state.dateFilterType = "month";
    },
    ENABLE_DATE_FILTER: (state) => {
      state.disable.dateFrom = false;
      state.disable.dateTo = false;
      state.disable.all = false;
    },
    ENABLE_DATE_TO_FILTER: (state) => {
      state.disable.dateTo = false;
      state.disable.all = false;
    },
    ENABLE_DATE_FROM_FILTER: (state) => {
      state.disable.dateFrom = false;
      state.disable.all = false;
    },
    ENABLE_SKU_FILTER: (state) => {
      state.disable.sku = false;
      state.disable.all = false;
    },
    ENABLE_LIMIT_FILTER: (state) => {
      state.disable.limit = false;
      state.disable.all = false;
    },
    ENABLE_CUSTOMER_FILTER: (state) => {
      state.disable.customer = false;
      state.disable.all = false;
    },
    ENABLE_CHANNEL_FILTER: (state) => {
      state.disable.channel = false;
      state.disable.all = false;
    },
    DISABLE_ALL_FILTERS: (state) => {
      state.disable.all = true;
    },
    ENABLE_ALL_FILTERS: (state) => {
      state.disable.all = false;
    },
    DISABLE_APP_BAR: (state) => {
      state.disable.appBar = true;
    },
    ENABLE_APP_BAR: (state) => {
      state.disable.appBar = false;
    },
  },
  actions: {
    setQueryString: function (context) {
      let urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams");
      console.log(urlParams);
      urlParams.forEach((v, k) => {
        console.log("query param");
        console.log(`${k} = ${v}`);
        context.commit("SET_QUERY_PARAMETER", {"key": k, "value": v});
      });
    },
    setFrom: function (context, data) {
      context.commit("SET_FROM", data);
    },
    setTo: function (context, data) {
      context.commit("SET_TO", data);
    },
    setSKU: function (context, data) {
      context.commit("SET_SKU", data);
    },
    setLimit: function (context, data) {
      context.commit("SET_LIMIT", data);
    },
    initializeFilters: function (context) {
      if (context.getters.getDateFrom === null) {
        let fromDate = moment().subtract(8, "days").format("YYYY-MM-DD");
        context.commit("SET_FROM", fromDate);
      }
      if (context.getters.getDateTo === null) {
        let toDate = moment().subtract(1, "day").format("YYYY-MM-DD");
        context.commit("SET_TO", toDate);
      }
    },
    hideFilterDialog: function (context) {
      context.commit("HIDE_FILTER_DIALOG");
    },
    showFilterDialog: function (context) {
      context.commit("SHOW_FILTER_DIALOG");
    },
    enableFilters: function (context) {
      context.commit("ENABLE_ALL_FILTERS");
      context.commit("ENABLE_DATE_TO_FILTER");
      context.commit("ENABLE_DATE_FROM_FILTER");
      context.dispatch("initializeFilters");
    },
    disableFilters: function (context) {
      context.commit("DISABLE_ALL_FILTERS");
    },
    setFilterDates: function (context, data) {
      if (data.from) {
        context.commit("SET_FROM", data.from);
      }
      if (data.to) {
        context.commit("SET_TO", data.to);
      }
    },
    triggerUpdate: function (context) {
      context.commit("SET_UPDATED");
    },
  },
};

export { filters };
