var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PaceWidget"},[_c('widget-wrapper',[_c('h2',[_vm._v("Channel Pace")]),_c('p',[_vm._v("How much you need to sell each day to hit targets. Need updated annual target information.")]),_c('div',_vm._l((_vm.paceData),function(p,key){return _c('v-progress-linear',{key:key,attrs:{"value":p.percent * 100,"color":_vm.paceColor(p.percent),"height":"25"}},[_c('strong',[_vm._v(_vm._s(p.channel)+"("+_vm._s(_vm.formatNumber(p.percent * 100, 2))+" %, "+_vm._s(_vm.formatNumber(p.currentPace, 2))+" / "+_vm._s(_vm.formatNumber(p.neededPace, 2))+")")])])}),1),(false)?_c('div',[_c('generic-table',{attrs:{"dataset":_vm.paceData,"item-class":_vm.rowClass,"headers":[
          {text: "Channel", value: "channel", sortable: true},
          {text: "THB", value: "amount",align: "right", sortable: true},
          {text: "C Pace", value: "currentPace",align: "right", sortable: true},
          {text: "N Pace", value: "neededPace",align: "right", sortable: true} ]},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" ")]}},{key:"item.currentPace",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.currentPace, 2))+" ")]}},{key:"item.neededPace",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.neededPace, 2))+" ")]}}],null,true)})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }