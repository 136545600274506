const filterMixins = {
  data: function () {
    return {
      a: "b"
    };
  },

  methods: {
    _filterHasData: function (obj) {
      if (!(
        (obj === "") ||
        (obj === "False") ||
        (obj === false) ||
        (obj === 0) ||
        (obj === null) ||
        (typeof obj === "undefined")
      )) {
        return true;
      }
      return false;
    },
    filterDataURL: function (url = "", fromDate = "", toDate = "", sku = "", limit = "") {
      let result = {};
      if (this._filterHasData(fromDate)) {
        result.from = fromDate;
      }

      if (this._filterHasData(toDate)) {
        result.to = toDate;
      }

      if (this._filterHasData(sku)) {
        result.sku = sku;
      }

      if (this._filterHasData(limit)) {
        result.limit = limit;
      }

      if (Object.keys(result).length > 0) {
        let qs = Object.keys(result).map(x => `${x}=${result[x]}`).join("&");
        let host = window.location.host;
        let protocol = window.location.protocol;
        return `${protocol}//${host}${url}?${qs}`;
      } else {
        return url;
      }
    }
  }
};
export { filterMixins };
