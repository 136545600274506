import { mapGetters } from "vuex";
import { isArray } from "chart.js/helpers";

const authMixins = {
  computed: {
    ...mapGetters("user", {
      authUserRoles: "roles"
    })
  },
  methods: {
    /**
     *
     * @param {Array<string>}userRoles
     * @param {Array<string>}appRoles
     * @returns {boolean}
     */
    authUserHasRoles: function (userRoles, appRoles) {
      return userRoles.some(item => appRoles.includes(item));
    },
    /**
     * Check to see if the user can achieve a role or an array of roles
     * @param {string|Array<string>} checkRoles
     * @returns {boolean}
     */
    authCheckUserCan: function (checkRoles) {
      if (checkRoles === null) {
        return false;
      } else if (typeof checkRoles == "string") {
        checkRoles = [checkRoles];
      } else if (!isArray(checkRoles)) {
        throw "Invalid Type";
      }
      return this.authUserHasRoles(this.authUserRoles, checkRoles);
    }
  }
};
export { authMixins };
