<template>
  <div class="HomeView">
    <responsive-wrapper>
      <dashboard-title>Welcome to Visual Express {{ name }}</dashboard-title>
      <p v-if="isUpdating">The system is updating. You can view the
        <v-btn text @click="$router.push({name:'status'})">status</v-btn>
        .
      </p>
      <sales-dashboard/>
    </responsive-wrapper>
  </div>
</template>

<style lang="postcss" scoped>
.HomeView {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { mapState, mapMutations, mapGetters } from "vuex";
import ResponsiveWrapper from "@/components/layout/ResponsiveWrapper";
import DashboardTitle from "@/components/layout/DashboardTitle";
import SalesDashboard from "@/components/dashboards/SalesDashboard";
import { authMixins } from "@/mixins/authMixins";

export default {
  name: "HomeView",
  components: {SalesDashboard, DashboardTitle, ResponsiveWrapper},
  props: {
    // https://vuejs.org/v2/guide/components.html#Prop-Validation
    // propname: String
  },
  computed: {
    ...mapGetters("meta", ["isUpdating"]),
    ...mapState("user", ["name"])
  },
  watch: {},
  mixins: [authMixins],
  data: function () {
    return {};
  },
  methods: {
    // ...mapActions('',[''])
    ...mapMutations("filters", ["DISABLE_ALL_FILTERS"])
  },
  created() {
  },
  mounted() {
    this.DISABLE_ALL_FILTERS();
  },
};
</script>
