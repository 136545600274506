<template>
  <div class="DhoLeftNav">
    <v-card>
      <v-navigation-drawer
          v-model="drawer"
          app
          temporary>

        <v-list dense nav>
          <v-list-item>
            <v-list-item-subtitle>
              Menu
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list v-for="(item, key) in menuItems"
                :key="key"
                dense nav>
          <v-list-item v-if="canShow(item)" @click="itemClick(item.link)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<style lang="postcss" scoped>
.DhoLeftNav {
  text-align: left;

  .status-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;

    .status-class {
      padding: 2px 4px 2px 4px;
      border-radius: 4px;
      color: white;

      &.updating {
        background-color: red;
      }

      &.ok {
        background-color: green;
      }
    }
  }
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { mapState, mapMutations, mapGetters } from "vuex";
import { apiMixins } from "@/mixins/apiMixins";
import { authMixins } from "@/mixins/authMixins";

export default {
  name: "DhoLeftNav",
  components: {},
  props: {
    //propName: {
    //    type: String,
    //    required: false,
    //    default: '',
    //}
  },
  computed: {
    ...mapGetters("filters", ["filteredQueryString"]),
    ...mapState("menus", ["showLeftNav"]),
    menuItems: function () {
      return [
        {
          link: "home",
          icon: "mdi-view-dashboard-variant",
          title: "Dashboard"
        },
        {
          link: "report_list",
          icon: "mdi-finance",
          title: "Reports"
        },
        {
          link: "status",
          icon: "mdi-sync",
          title: "Status",
        },
        {
          link: "usage",
          icon: "mdi-account",
          title: "Usage",
          roles: ["dev"]
        },
        {
          link: "debug",
          icon: "mdi-bug",
          title: "Debug",
          roles: ["dev"]
        }
      ];
    },
    drawer: {
      get() {
        return this.showLeftNav;
      },
      set(value) {
        if (value) {
          this.SET_LEFT_NAV(value);
        } else {
          this.SET_LEFT_NAV(false);
        }
      }
    },
  },
  watch: {
    // showLeftNav: function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.drawer = newVal;
    //   }
    // },
    // drawer: function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.SET_LEFT_NAV(false);
    //   }
    // }
  },
  mixins: [apiMixins, authMixins],
  data: function () {
    return {
      metaData: {}
      // drawer: false,
    };
  },
  methods: {
    // ...mapActions('',[''])
    ...mapMutations("menus", ["SET_LEFT_NAV"]),
    generateLink: function (link) {
      return `${link}?${this.filteredQueryString}`;
    },
    itemClick: function (linkName) {
      this.$router.push({name: linkName});
    },
    canShow: function (item) {
      if (item == null) {
        return false;
      }
      if (item.roles) {
        return this.authCheckUserCan(item.roles);
      } else {
        return true;
      }
    },
  },
  async created() {
    // this.menuItems = await this.apiGetData("/api/v1/home/control/menu");
    // this.metaData = await this.apiGetData("/api/v1/home/meta/meta/");
  },
  mounted() {
    console.log(this.menuItems);
  },
};
</script>
