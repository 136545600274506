<template>
  <div class="WidgetWrapper py-8">
    <div v-if="userHasPermission">
      <div v-if="!isReady">
        <dho-loading/>
      </div>
      <div v-else>
        <v-card class="mb-8">
          <v-card-title>
            <widget-title v-if="title !== ''">{{ title }}</widget-title>
          </v-card-title>
          <v-card-text>
            <slot/>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.WidgetWrapper {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import WidgetTitle from "@/components/layout/WidgetTitle";
import DhoLoading from "@/components/widgets/DhoLoading";
import { authMixins } from "@/mixins/authMixins";

export default {
  name: "WidgetWrapper",
  components: {DhoLoading, WidgetTitle},
  props: {
    // https://vuejs.org/v2/guide/components.html#Prop-Validation
    // propname: String
    title: {
      type: String,
      default: "",
      required: false,
    },
    isReady: {
      type: Boolean,
      default: true,
      required: false
    },
    roles: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  computed: {
    // ...mapGetters('',[''])
    // ...mapState('',[''])
    userHasPermission: function () {
      let numRoles = this.roles?.length ?? 0;
      if (numRoles > 0) {
        return this.authCheckUserCan(this.roles);
      } else {
        return true;
      }
    },
  },
  watch: {},
  mixins: [authMixins],
  data: function () {
    return {};
  },
  methods: {
    // ...mapActions('',[''])
    // ...mapMutations('',[''])
  },
  created() {
  },
  mounted() {
  },
};
</script>
