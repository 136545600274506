import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

const persisted = {
  namespaced: true,
  state: {
    saved: {
      debugUrls: []
    }
  },
  getters: {
    debugUrls: function (state) {
      return state.saved.debugUrls;
    },
  },
  mutations: {
    SAVE_DEBUG_URL: function (state, data) {
      let tmp = [];
      if (!state.saved.debugUrls.includes(data)) {
        if (state.saved.debugUrls.length > 4) {
          tmp = state.saved.debugUrls.shift();
        } else {
          tmp = state.saved.debugUrls;
        }
        tmp.push(data);
        Vue.set(state.saved, "debugUrls", tmp);
      }
    }
  },
  actions: {
    saveDebugURL: async function (context, data) {
      context.commit("SAVE_DEBUG_URL", data);
      return context.getters.debugUrls;
    }
  }
};


const persistedStatePlugin = createPersistedState({
  paths: ["persisted"]
});


export { persisted, persistedStatePlugin };
