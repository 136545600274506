<template>
  <div id="app">
    <v-app>
      <dho-toolbar/>
      <dho-left-nav/>
      <div class="pb-3">&nbsp;</div>
      <router-view v-if="isAuthorized"/>
      <!--      <router-view/>-->
      <slot></slot>
    </v-app>
  </div>
</template>

<script>

import DhoToolbar from "./components/navigation/DhoToolbar";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import DhoLeftNav from "./components/navigation/DhoLeftNav";
// import { auth } from "@/plugins/fb";
import { NoFirebaseTokenException } from "@/plugins/exceptions";

export default {
  name: "App",
  components: {
    DhoLeftNav,
    DhoToolbar
  },
  computed: {
    ...mapState("filters", ["from", "to", "sku"]),
    ...mapGetters("user", ["haveToken"]),
    ...mapState(["showLeftNav"]),
    isAuthorized: function () {
      if (this.$route.name === "login") return true;
      return this.haveToken;
    },
    dateRangeLabel: function () {
      let label = "";
      if (((this.to === "") || (this.to === null)) && ((this.from === "") || (this.from === null))) {
        return "";
      }

      if ((this.to === "") || (this.to === null)) {
        label = `to <strong>today</strong>`;
      } else {
        label = `to <strong>${this.to}</strong>`;
      }

      if ((this.from === "") || (this.from === null)) {
        label.replace("to", "To");
      } else {
        label = `From <strong>${this.from}</strong> ${label}`;
      }

      return label;
    },
    skuLabel: function () {
      if (!((this.sku === "") || (this.sku === null))) {
        return this.sku;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("filters", ["setFrom", "setTo", "setSKU", "setQueryString"]),
    // ...mapActions(["logInUser", "logOutUser"]),
    ...mapActions("user", ["logIn", "logOut", "updateToken", "bindUser"]),
    ...mapActions("meta", ["bindCurrentRun", "bindSkus", "bindUsage"]),
    ...mapMutations("menus", ["TOGGLE_LEFT_NAV",]),
    parseParams: function () {
      const urlParams = new URLSearchParams(window.location.search);
      this.setFrom(urlParams.get("from"));
      this.setTo(urlParams.get("to"));
      this.setSKU(urlParams.get("sku"));
      console.log(urlParams);
    },
    checkToken: async function () {
      let self = this;
      this.$firebase.auth().onIdTokenChanged(async function (user) {
        if (user) {
          console.log("idToken changed.");
          let idToken = await user?.getIdToken() ?? '';
          let email = user.email;
          // let name = user.displayName;
          self.$cookies.set("abt", idToken);
          // console.log(idToken); // It shows the Firebase token now
          // self.logInUser({
          //   token: idToken,
          //   email
          // });
          try {
            self.updateToken(idToken);
            // self.logIn({
            //   token: idToken,
            //   email,
            //   name
            // });
            self.bindUsage();
            self.bindUser(email);
            self.bindCurrentRun();
            self.bindSkus();
          } catch (err) {
            if (err instanceof NoFirebaseTokenException) {
              console.error(err);
            }
          }
        } else {
          // remove this
          // console.log("Check Token: There is no user, we are going to clear the contents of store.");
          // self.logOut();
          // if (self.$route.name !== "login") {
          //   console.log("Pushing user to login.");
          //   self.$router.push({name: "login"});
          // }
          // No user is signed in.
        }
      });
    },
    checkAuth: async function () {
      let self = this;
      this.$firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          console.log("Check Auth: There is a user, we are going to log him in.");
          let idToken = await user?.getIdToken() ?? '';
          let email = user.email;
          let name = user.displayName;
          self.$cookies.set("abt", idToken);
          // console.log(idToken); // It shows the Firebase token now
          // We remove this
          // await self.logInUser({
          //   token: idToken,
          //   email
          // });
          try {
            await self.logIn({
              token: idToken,
              email,
              name
            });
            self.bindUser(email);
            self.bindCurrentRun();
            self.bindSkus();
          } catch (err) {
            if (err instanceof NoFirebaseTokenException) {
              console.error(err);
            }
          }

        } else {
          self.logOut().then(() => {
            console.log("checkAuth(): logged out");
            if (self.$route.name !== "login") {
              console.log("Pushing user to login.");
              self.$router.push({name: "login"}).then(() => console.log("checkAuth(): pushed to login"));
            }
          });
          // console.log("checkAuth(): There is no user, we are going to clear the contents of store.");
          // self.logOut().then(() => console.log("checkAuth(): logged out"));
          // if (self.$route.name !== "login") {
          //   console.log("Pushing user to login.");
          //   self.$router.push({name: "login"}).then(() => console.log("checkAuth(): pushed to login"));
          // }
          // No user is signed in.
        }
      });
    },
  },
  data: function () {
    return {
      gettingToken: false,
    };
  },
  created() {
    this.checkAuth();
    this.checkToken();
    // this.setQueryString();
    // this.parseParams();
  },
  mounted() {
    console.log("Mounted app!");
    // let self = this;
    this.updateToken({token: ""});
    console.log("There is a current user.");
    this.$firebase.auth().currentUser?.getIdToken(true).then(value => {
      console.log("getIdToken()");
      console.log(value);
      this.gettingToken = false;
    });
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 80px;
}

.detail {
  font-size: 1.5em;
  font-weight: bold;

  .sku {
    font-weight: bold;
  }
}
</style>
