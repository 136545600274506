import Vue from "vue";
import { NoFirebaseTokenException } from "@/plugins/exceptions";
import { firestoreAction } from "vuexfire";
import { db } from "@/plugins/fb";
// import { doc, getDoc } from "firebase/firestore";

const user = {
  namespaced: true,
  state: {
    token: "",
    email: "",
    name: "",
    roles: [],
    fbUser: {}
  },
  getters: {
    roles: function (state) {
      if (state.fbUser.roles) {
        return state.fbUser.roles;
      } else {
        return [];
      }
    },
    haveToken: function (state) {
      return (state.token !== "");
    },
    isLoggedIn: function (state) {
      return state.token !== "";
    }
  },
  mutations: {
    LOG_IN: function (state, data) {
      let token = data.token;
      let email = data.email;
      let name = data.name;
      Vue.set(state, "token", token);
      Vue.set(state, "email", email);
      Vue.set(state, "name", name);
    },
    LOG_OUT: function (state) {
      Vue.set(state, "token", "");
      Vue.set(state, "email", "");
      Vue.set(state, "name", "");
    },
    UPDATE_TOKEN: function (state, data) {
      Vue.set(state, "token", data.token);
    },
  },
  actions: {
    updateToken: function (context, data) {
      let token = data.token;
      context.commit("UPDATE_TOKEN", {
        token
      });
    },
    logIn: async function (context, data) {
      if ((!data.token) || (data.token === "")) {
        throw new NoFirebaseTokenException("No token when logging in.");
      }
      let token = data.token;
      let email = data.email || "";
      let name = data.name || "";
      context.commit("LOG_IN", {
        token,
        email,
        name
      });
    },
    logOut: async function (context) {
      context.commit("LOG_OUT");
    },
    bindUser: firestoreAction(({bindFirestoreRef}, userEmail) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("fbUser", db.collection("visex_users").doc(userEmail));
    }),
    /*bindUser2: firestoreAction(({bindFirestoreRef}, userEmail) => {
      // return the promise returned by `bindFirestoreRef`
      console.log("Hello");
      console.table(db);
      console.log(userEmail);
      // return bindFirestoreRef("fbUser", db.collection("visex_users").doc(userEmail));
      return bindFirestoreRef("fbUser", getDoc(doc(db, "visex_users", userEmail)));
    }),*/
  },
};

export { user };
