<template>
  <div class="PaceWidget">
    <widget-wrapper>
      <h2>Channel Pace</h2>
      <p>How much you need to sell each day to hit targets. Need updated annual target information.</p>
      <div>
        <v-progress-linear
            v-for="(p, key) in paceData"
            :value="p.percent * 100"
            :color="paceColor(p.percent)"
            height="25"
            :key="key">
          <strong>{{ p.channel }}({{ formatNumber(p.percent * 100, 2) }} %, {{ formatNumber(p.currentPace, 2) }} /
            {{ formatNumber(p.neededPace, 2) }})</strong>
        </v-progress-linear>
      </div>
      <div v-if="false">
        <generic-table
            :dataset="paceData"
            :item-class="rowClass"
            :headers='[
            {text: "Channel", value: "channel", sortable: true},
            {text: "THB", value: "amount",align: "right", sortable: true},
            {text: "C Pace", value: "currentPace",align: "right", sortable: true},
            {text: "N Pace", value: "neededPace",align: "right", sortable: true},
          ]'>
          <template v-slot:[`item.amount`]="{item}">
            {{ formatNumber(item.amount) }}
          </template>
          <template v-slot:[`item.currentPace`]="{item}">
            {{ formatNumber(item.currentPace, 2) }}
          </template>
          <template v-slot:[`item.neededPace`]="{item}">
            {{ formatNumber(item.neededPace, 2) }}
          </template>
        </generic-table>
      </div>
    </widget-wrapper>
  </div>
</template>

<style lang="postcss" scoped>
.PaceWidget {
  .table-falling-behind {
    background-color: red;
    color: white;
  }
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import WidgetWrapper from "@/components/layout/WidgetWrapper";
import { apiMixins } from "@/mixins/apiMixins";
import moment from "moment";
import humanize from "humanize";
import GenericTable from "@/components/graphs/GenericTable";
import { formatMixin } from "@/mixins/formatMixin";

export default {
  name: "PaceWidget",
  components: {GenericTable, WidgetWrapper},
  props: {
    // https://vuejs.org/v2/guide/components.html#Prop-Validation
    // propname: String
  },
  computed: {
    // ...mapGetters('',[''])
    // ...mapState('',[''])
    todayDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    beginningOfYear: function () {
      return this.todayDate.split("-")[0] + "-01-01";
    },
    daysThisYear: function () {
      return moment().diff(moment(this.beginningOfYear), "days");
    },
    paceData: function () {
      let data = this.channelSales.map(x => {
        let target = this.targets[x.channel.toLowerCase()];
        console.log("Pace Data");
        console.log(target);
        let currentPace = x.amount / this.daysThisYear;
        let neededPace = (target - x.amount) / (366 - this.daysThisYear);
        let percent = 0;
        if (typeof target === "undefined") {
          percent = 1;
        } else if (neededPace === 0) {
          percent = 0;
        } else if (neededPace < 0) {
          percent = 1;
        } else {
          percent = currentPace / neededPace;
        }
        return {
          channel: x.channel,
          amount: x.amount,
          currentPace,
          neededPace,
          percent
        };
      });
      data.sort((a, b) => b.percent - a.percent);
      return data;
    },
  },
  watch: {},
  mixins: [apiMixins, formatMixin],
  data: function () {
    return {
      targets: {
        "exportx": 74815934.68,
        "exportc": 74815934.68,
        "mt": 74815934.68,
        "ecomm": 35156506.87,
        "online": 35156506.87,
        "foem": 38194183,
        "fchain": 26609976.67,
        "other": 25230659.28,
      },
      channelSales: []
    };
  },
  methods: {
    // ...mapActions('',[''])
    // ...mapMutations('',[''])
    loadData: async function () {
      console.log("Loading PaceWidget data");
      this.channelSales = await this.apiGetData("/sales/channel_summary", {
        from: this.beginningOfYear,
        to: this.todayDate
      });
    },
    readable: function (amount) {
      return humanize.numberFormat(amount, 2);
    },
    rowClass: function (item) {
      console.log("Figuring out item");
      console.log(item);
      if (item.neededPace > item.currentPace) {
        return "table-falling-behind";
      }
    },
    paceColor: function (pace) {
      let color = "red";
      if (pace >= .9) {
        color = "green";
      } else if (pace > .6 && pace < .9) {
        color = "amber";
      } else {
        color = "red";
      }
      return color;
    }
  },
  created() {
  },
  mounted() {
    this.loadData();
  },
};
</script>
