import { render, staticRenderFns } from "./SalesDashboard.vue?vue&type=template&id=de33b8f6&scoped=true"
import script from "./SalesDashboard.vue?vue&type=script&lang=js"
export * from "./SalesDashboard.vue?vue&type=script&lang=js"
import style0 from "./SalesDashboard.vue?vue&type=style&index=0&id=de33b8f6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de33b8f6",
  null
  
)

export default component.exports