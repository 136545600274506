class NoFirebaseTokenException extends Error {
  constructor(message, cause = "") {
    super(message);
    this.cause = cause;
    this.name = "NoFirebaseTokenException";
  }
}

class ApiGetDataException extends Error {
  constructor(message, cause = "") {
    super(message);
    this.cause = cause;
    this.name = "ApiGetDataException";
  }
}

export { NoFirebaseTokenException, ApiGetDataException };
