import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

// import { firebaseConfig } from "@/keys/fbconfig-mokipick";
import { firebaseConfig } from "@/keys/fbconfig";

// initialize Firebase first
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const db = firebase.firestore();
const functions = firebase.functions();
const fc = functions;
const {TimeStamp, GeoPoint} = firebase.firestore;
const auth = firebase.auth();

// determine if we use the emulator
const USE_EMULATOR = location.hostname === "localhost";
// const USE_EMULATOR = false;
if (USE_EMULATOR) {
  // db.useEmulator("localhost", 9000);
  // functions.useEmulator("http://localhost:5001");
  // functions.useEmulator("localhost", 5001);
  // auth.useEmulator("http://localhost:9099");
}

const FirebaseVue = {
  // eslint-disable-next-line
  install: function (Vue, options) {
    // const api = new API()

    Vue.prototype.$firebase = firebase;
    Vue.prototype.$firebaseAuth = auth;
    // Vuex.Store.prototype.$util = util
  },
};
export { FirebaseVue, db, auth, fc, functions, TimeStamp, GeoPoint };
