const firebaseConfig = {
    apiKey: "AIzaSyCLTX6I6JXHZd2Fazkwy_3veb-Uzyszafs",
    authDomain: "mokifoodie.firebaseapp.com",
    databaseURL: "https://mokifoodie.firebaseio.com",
    projectId: "mokifoodie",
    storageBucket: "mokifoodie.appspot.com",
    messagingSenderId: "754680421283",
    appId: "1:754680421283:web:6812a07621ee16d65f5816",
    measurementId: "G-Z55ZSCZP3W"
};

export {firebaseConfig}