import Vue from "vue";

const menus = {
  namespaced: true,
  state: {
    showMainMenu: false,
    showLeftNav: false,
  },
  getters: {},
  mutations: {
    SET_MAIN_MENU: (state, data) => {
      Vue.set(state, "showMainMenu", data);
    },
    TOGGLE_LEFT_NAV: (state) => {
      state.showLeftNav = !state.showLeftNav;
    },
    SET_LEFT_NAV: (state, data) => {
      state.showLeftNav = data;
    }
  },
  actions: {
    setMainMenu: function (context, data) {
      context.commit("SET_MAIN_MENU", data);
    },
  },
};

export { menus };
