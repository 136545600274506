<template>
  <div class="ResponsiveWrapper px-xs-1 px-md-3 px-lg-5">
    <v-container>
      <slot/>
    </v-container>
  </div>
</template>

<style lang="postcss" scoped>
.ResponsiveWrapper {
}
</style>

<script>
// import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: "ResponsiveWrapper",
  components: {},
  props: {
    //propName: {
    //    type: String,
    //    required: false,
    //    default: '',
    //}
  },
  computed: {
    // ...mapGetters('',[''])
    // ...mapState('',[''])
  },
  watch: {},
  mixins: [],
  data: function () {
    return {};
  },
  methods: {
    // ...mapActions('',[''])
    // ...mapMutations('',[''])
  },
  created() {
  },
  mounted() {
  },
};
</script>
